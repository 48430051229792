import { mapGetters, mapMutations /*mapState*/ } from 'vuex';
export default {
  name: 'TeacherDashboardIndex',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['nameUserLoggedIn']),
  },
  created() {
    this.setIsLogin(false);
  },
  methods: {
    ...mapMutations(['setIsLogin']),
  },
};
